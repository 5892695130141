import * as React from 'react'
import { isIOS, isAndroid } from 'react-device-detect';

import { ReactComponent as Logo } from './assets/logo.svg';
import appstore from './assets/appstore.png'
import googleplay  from './assets/googleplay.png'
import './App.css';


const GOOGLE_PLAY_STORE_URL =
  "https://play.google.com/store/apps/details?id=no.viappen.viappen";
const APP_STORE_URL = "https://apps.apple.com/us/app/vi-appen/id1461463797";


const IOSButton = () => <a className="icon-store" href={APP_STORE_URL}><img  alt="App Store" src={appstore} /></a>
const AndroidButton = () => <a className="icon-store" href={GOOGLE_PLAY_STORE_URL}><img alt="Google Play" src={googleplay} /></a>

const renderButtons = () => {
  if (isIOS) {
    return <IOSButton />
  }
  if (isAndroid) {
    return <AndroidButton />
  }

  return (
    <>
      <IOSButton />
      <AndroidButton />
    </>
  )
}

function App() {
  return (
    <div className="root">
      <Logo width={160} />
      <h1>Styrker foreldre og par</h1>
      <div className="buttons">
        {renderButtons()}
      </div>
      <p className="footer-text">Lær mer om VI-appen på <a href="https://vi-appen.no">vi-appen.no</a></p>
    </div>
  );
}

export default App;
